import * as React from "react"

import Layout from "../components/layout"
// import Seo from "../components/seo"
import Seo2 from "../components/Seo2"
import { Link } from "gatsby"
import PageHeader from '../components/PageHeader'
import Container from "../components/Container"
import Button from "../components/Button"

const NotFoundPage = () => (
  <Layout>
    {/* <Seo title="404: Not found" /> */}
    <div className={`bg-accent-lighter -mb-20`}>
      <div className={`py-20`}>
      <PageHeader
      pageTitle="Page Not Found"
      preheader="WOOF!"
      className="-mt-10 max-w-2xl mx-auto text-center">
        <p className="mt-5 mb-0 lg:px-20 xl:px-32">
        Uh oh! It looks like you might be lost. Feel free to <Link to={`/contact`} className="text-primary-default hover:text-primary-darker border-b border-primary-default hover:border-primary-darker">contact us</Link> if you have any questions.
        </p>
      </PageHeader>
      <Container>
      <div className={`text-center md:-mt-10`}>
          <Button to={`/`} variation={`secondary`}>Go Back Home</Button>
        </div>
      </Container>
      </div>
    </div>
  </Layout>
)

export const Head = () => <Seo2 title="Not Found" />

export default NotFoundPage
